import quotesModule from '@modules/quotes/module';
import leadsModule from '@modules/leads/module';
import hubspotModule from '@modules/hubspot/module';
import accountModule from '@modules/account/module';

export const gtmConfig = {
  containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
};

export const languages = [
  {
    lang: 'de',
    label: 'Deutsch',
    icon: '/static/icons/de_flag.svg',
  },
  {
    lang: 'en',
    label: 'English',
    icon: '/static/icons/uk_flag.svg',
  },
];

export const routes = {
  default: '/leads',
  auth: {
    login: '/auth/login',
  },
};

export const modules = [
  leadsModule,
  quotesModule,
  hubspotModule,
  accountModule,
];

export function getSidebarSections(t, router) {
  return [
    ...modules.flatMap((module) => module.menu?.getSections(t, router) ?? []),
  ];
}

export const TAGS = [
  {
    id: 1,
    label: '1 Tag',
  },
  {
    id: 2,
    label: '2 Tage',
  },
  {
    id: 3,
    label: '3 Tage',
  },
  {
    id: 4,
    label: '4 Tage',
  },
  {
    id: 5,
    label: '5 Tage',
  },
  {
    id: 6,
    label: '6 Tage',
  },
  {
    id: 7,
    label: '7 Tage',
  },
];

export const ROOMS = [
  { id: '1', label: '1 Raum' },
  { id: '2', label: '2 Räume' },
  { id: '3', label: '3 Räume' },
  { id: '4', label: '4 Räume' },
  { id: '5', label: '5 Räume' },
];
