import { IcQuotes } from '@components/icons';
import resources from './i18n';

export const rootPath = '';

const config = {
  name: 'quotes',
  rootPath,
  i18n: {
    resources,
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.quotes.title'),
        acl: [],
        items: [
          {
            title: t('modules.quotes.menu.quotes'),
            path: `${rootPath}/quotes`,
            icon: <IcQuotes />,
            acl: [
              'api::quote.quote.find',
            ],
          },
        ],
      },
    ],
  },
};

export default config;
