import { gql, useQuery } from '@apollo/client';
import { selectCurrentTenant, setTenant } from '@slices/tenant';
import { useDispatch, useSelector } from 'react-redux';

import ErrorPage from 'next/error';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export default function AppBaseProvider({ host, children, fallback }) {
  const dispatch = useDispatch();
  const tenant = useSelector(selectCurrentTenant);
  const { data, error, loading } = useQuery(BASE_QUERY, {
    variables: {
      host,
    },
  });

  useEffect(() => {
    if (!data) { return; }

    if (data.tenants.data.length > 0) {
      dispatch(setTenant(data.tenants.data[0]));
    }
  }, [data, dispatch]);

  if (loading || !tenant) {
    return fallback;
  }

  if (error) {
    console.error(error);
  }

  if (data && data.tenants.data.length === 0) {
    return <ErrorPage statusCode={404} />;
  }

  return children;
}

AppBaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node.isRequired,
};

const BASE_QUERY = gql`
query Fetch($host: String!) {
  tenants(filters: { host: { eq: $host } }) {
    data {
      id
      attributes {
        host
        name
      }
    }
  }
}
`;
