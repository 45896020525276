import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      fill="none"
      height="1em"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 32 32"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.59 4.59v18.79h26.85V12.64l-8.06-8.05H4.59z" />
      <path d="M31.44 12.64h-8.06V4.59M4.59 8.62H.56v18.79h26.85v-4.03" />
    </svg>
  );
}

export default SvgComponent;
