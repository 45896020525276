import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      fill="none"
      height="1em"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 32 32"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M21.38 31.45H2.56V4.58h12.1l6.72 6.72v20.15z" />
      <path d="M14.66 4.58v6.72h6.72" />
      <path d="M10.62 4.58V.55h12.1l6.72 6.72v20.15h-8.06" />
      <path d="M22.72.55v6.72h6.72" />
    </svg>
  );
}

export default SvgComponent;
