import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const Logo = styled((props) => {
  const { variant, ...other } = props;
  return variant === 'light' ? <Dark {...other} /> : <Light {...other} />;
})``;

Logo.defaultProps = {
  variant: 'primary',
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary']),
};

function Light(props) {
  return (
    <svg
      height="52.22363"
      viewBox="0 0 147.4 51.02"
      width="147.40183"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <style>{'.cls-1{fill:#23324b;}.cls-2{fill:#fff;}.cls-3{fill:#ffd200;}'}</style>
      </defs>
      <g data-name="Ebene 2" id="Ebene_2">
        <g id="Licht">
          <path
            className="cls-1"
            d="M62.36,26.86h2V11h-2V9.11H78.42v5.3H75V11.22H69.18v6.62h3.61V16.21H75v5.38H72.79V20H69.18v6.9h2.73v1.91H62.36Z"
          />
          <path
            className="cls-1"
            d="M81,26.86h1.44L88.64,9.11h4.09L99,26.86h1.44v1.91H91.8V26.86h2.25L92.7,22.8H86.28l-1.36,4.06h2.26v1.91H81Zm5.89-6h5.29l-2.62-8.17h-.11Z"
          />
          <path
            className="cls-1"
            d="M104.73,26.86h2V11h-2V9.11h11.43c3.55,0,6.17,1.44,6.17,5,0,2.73-2,4.48-4.45,5l3.75,6.14a2.74,2.74,0,0,0,2.7,1.64h.11v1.91h-2.81c-2.73,0-4-.81-5-2.76l-3-5.94h-2.06v6.79h2v1.91h-8.78Zm6.81-8.76h2.74c2,0,3-.79,3-2.6V13.81c0-1.8-1-2.59-3-2.59h-2.74Z"
          />
          <path
            className="cls-1"
            d="M128.41,18.94c0-6.56,4.12-10.17,9.5-10.17s9.49,3.61,9.49,10.17-4.11,10.17-9.49,10.17S128.41,25.51,128.41,18.94Zm13.64,3.35v-6.7c0-3.38-2-4.68-4.14-4.68s-4.14,1.3-4.14,4.68v6.7c0,3.38,1.94,4.68,4.14,4.68S142.05,25.67,142.05,22.29Z"
          />
          <path
            className="cls-1"
            d="M69.88,42.82h0a2.28,2.28,0,0,1-2.43,1.43c-2.14,0-3.63-1.59-3.63-4.52s1.48-4.52,3.72-4.52a3.35,3.35,0,0,1,3.18,1.91l-.86.53a2.42,2.42,0,0,0-2.32-1.51A2.59,2.59,0,0,0,64.91,39v1.42a2.61,2.61,0,0,0,2.69,2.88c1.21,0,2.23-.63,2.23-1.92v-.86H67.9V39.6h3v4.5h-1Z"
          />
          <path
            className="cls-1"
            d="M77.1,44.1h-1V35.36h3.48A2.25,2.25,0,0,1,82,37.84a2.23,2.23,0,0,1-1.76,2.4l2,3.86H81l-1.89-3.76H77.1Zm2.43-4.66a1.17,1.17,0,0,0,1.32-1.25v-.65a1.17,1.17,0,0,0-1.32-1.26H77.1v3.16Z"
          />
          <path
            className="cls-1"
            d="M88,35.36v5.41c0,1.66.53,2.54,2.09,2.54s2.09-.88,2.09-2.54V35.36h1v5.16c0,2.54-.85,3.73-3.18,3.73S87,43.06,87,40.52V35.36Z"
          />
          <path
            className="cls-1"
            d="M98.67,44.1V35.36h3.48a2.26,2.26,0,0,1,2.44,2.5,2.27,2.27,0,0,1-2.44,2.51H99.72V44.1Zm1-4.66h2.43a1.17,1.17,0,0,0,1.32-1.25v-.65a1.17,1.17,0,0,0-1.32-1.26H99.72Z"
          />
          <path
            className="cls-1"
            d="M109.39,44.1V35.36h3.48a2.26,2.26,0,0,1,2.44,2.5,2.27,2.27,0,0,1-2.44,2.51h-2.43V44.1Zm1-4.66h2.43a1.17,1.17,0,0,0,1.32-1.25v-.65a1.17,1.17,0,0,0-1.32-1.26h-2.43Z"
          />
          <path className="cls-1" d="M120.11,44.1V35.36h5.33v.92h-4.28v2.93h4v.93h-4v3h4.28v.93Z" />
          <path
            className="cls-1"
            d="M29.25,40.89a2.36,2.36,0,0,0,.55-.66c5.41-9.37,11.56-14.81,11.56-22.9a17.34,17.34,0,0,0-34.67,0c0,8.09,6.15,13.53,11.56,22.9a2.55,2.55,0,0,0,.55.66h0l-.12,2.6,1.73.52-1.78.53L18.56,46l2.28.67-2.34.7,0,.72c.88.17,1.78.31,2.69.4a2.9,2.9,0,0,0,5.76,0c.9-.09,1.8-.23,2.68-.4l0-.72-2.34-.7,2.28-.68-.07-1.39L27.65,44l1.73-.52-.09-1.87,0-.73Z"
          />
          <path
            className="cls-2"
            d="M24,14.45a10.94,10.94,0,0,1,6.21,2.06l-.12-2L24,9.46,17.9,14.52l-.09,2A11,11,0,0,1,24,14.45Z"
          />
          <path
            className="cls-2"
            d="M24,26.41a8.93,8.93,0,0,0-4.62,1.27L18.8,40.89h0a21,21,0,0,0,10.44,0h0l-.61-13.21A8.92,8.92,0,0,0,24,26.41Z"
          />
          <circle className="cls-3" cx="24.03" cy="20.72" r="2.64" />
          <path className="cls-3" d="M18.91,20.72H0a24.12,24.12,0,0,0,1.93,5.84l17-4.67Z" />
          <path
            className="cls-3"
            d="M49.19,14,29.11,19.58l0,1.14H49a22.54,22.54,0,0,0,.37-4.09A24.68,24.68,0,0,0,49.19,14Z"
          />
        </g>
      </g>
    </svg>
  );
}

function Dark(props) {
  return (
    <svg
      height="52.22363"
      viewBox="0 0 147.40183 52.22363"
      width="147.40183"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Ebene 2" id="b">
        <g data-name="FARO Gruppe" id="c">
          <g>
            <path d="M62.36246,27.45853h1.97146V11.62608h-1.97146v-1.91561h16.05683v5.2961h-3.46476v-3.18354h-5.77525v6.62086h3.60585v-1.63439h2.19781v5.38037h-2.19781v-1.63341h-3.60585v6.90208h2.73281v1.91463h-9.54963v-1.91463Z" fill="#fff" />
            <path d="M80.98062,27.45853h1.43646l6.22598-17.74805h4.085l6.22549,17.74805h1.43695v1.91463h-8.59232v-1.91463h2.25366l-1.3522-4.05659h-6.42293l-1.3522,4.05659h2.25366v1.91463h-6.19756v-1.91463Zm5.88793-6.02903h5.2961l-2.62012-8.16902h-.11268l-2.56329,8.16902Z" fill="#fff" />
            <path d="M104.72537,27.45853h1.97244V11.62608h-1.97244v-1.91561h11.43781c3.54902,0,6.16915,1.43646,6.16915,4.95805,0,2.73281-1.99988,4.4789-4.45147,5.0139l3.74695,6.14171c.76036,1.26793,1.46488,1.63439,2.70439,1.63439h.11268v1.91463h-2.81707c-2.73183,0-3.99975-.81622-4.98549-2.76024l-3.04342-5.94378h-2.05573v6.78939h1.97146v1.91463h-8.78927v-1.91463Zm6.81781-8.76183h2.73183c1.97244,0,3.015-.78878,3.015-2.59171v-1.69024c0-1.80293-1.04256-2.59171-3.015-2.59171h-2.73183v6.87366Z" fill="#fff" />
            <path d="M128.41426,19.54231c0-6.56403,4.11342-10.16988,9.49378-10.16988s9.49378,3.60585,9.49378,10.16988-4.11342,10.16988-9.49378,10.16988-9.49378-3.60585-9.49378-10.16988Zm13.63464,3.35207v-6.70415c0-3.38049-1.94403-4.67683-4.14085-4.67683-2.19781,0-4.14183,1.29634-4.14183,4.67683v6.70415c0,3.38049,1.94403,4.67585,4.14183,4.67585,2.19683,0,4.14085-1.29536,4.14085-4.67585Z" fill="#fff" />
          </g>
          <g>
            <path d="M69.87939,43.4222h-.03711c-.21289,.81348-1.10156,1.42773-2.42969,1.42773-2.14258,0-3.63281-1.59082-3.63281-4.52246,0-2.90625,1.47852-4.52246,3.7207-4.52246,1.56445,0,2.61719,.77637,3.18164,1.91699l-.86523,.52637c-.40039-.92773-1.21484-1.50391-2.31641-1.50391-1.54102,0-2.59375,1.13965-2.59375,2.86914v1.41504c0,1.72949,1.02734,2.88184,2.69336,2.88184,1.21484,0,2.23047-.62695,2.23047-1.91699v-.86426h-1.92969v-.92676h2.95703v4.49707h-.97852v-1.27734Z" fill="#fff" />
            <path d="M77.104,44.69955h-1.05273v-8.74414h3.48242c1.5293,0,2.44336,.88965,2.44336,2.48047,0,1.27734-.61328,2.14258-1.75391,2.40527l2.00391,3.8584h-1.18945l-1.89062-3.75781h-2.04297v3.75781Zm2.42969-4.66016c.82812,0,1.31641-.45117,1.31641-1.25293v-.65137c0-.80176-.48828-1.25293-1.31641-1.25293h-2.42969v3.15723h2.42969Z" fill="#fff" />
            <path d="M88.03564,35.95541v5.41211c0,1.66602,.53906,2.54297,2.09375,2.54297,1.55273,0,2.0918-.87695,2.0918-2.54297v-5.41211h1.05273v5.16113c0,2.54297-.85352,3.7334-3.18359,3.7334s-3.10547-1.19043-3.10547-3.7334v-5.16113h1.05078Z" fill="#fff" />
            <path d="M98.66845,44.69955v-8.74414h3.48242c1.56641,0,2.44336,.96484,2.44336,2.50586,0,1.54004-.87695,2.50488-2.44336,2.50488h-2.42969v3.7334h-1.05273Zm1.05273-4.66016h2.42969c.82617,0,1.31445-.45117,1.31445-1.25293v-.65137c0-.80176-.48828-1.25293-1.31445-1.25293h-2.42969v3.15723Z" fill="#fff" />
            <path d="M109.3872,44.69955v-8.74414h3.48242c1.56641,0,2.44336,.96484,2.44336,2.50586,0,1.54004-.87695,2.50488-2.44336,2.50488h-2.42969v3.7334h-1.05273Zm1.05273-4.66016h2.42969c.82812,0,1.31641-.45117,1.31641-1.25293v-.65137c0-.80176-.48828-1.25293-1.31641-1.25293h-2.42969v3.15723Z" fill="#fff" />
            <path d="M120.1079,44.69955v-8.74414h5.33594v.92676h-4.2832v2.93164h4.0332v.92676h-4.0332v3.03223h4.2832v.92676h-5.33594Z" fill="#fff" />
          </g>
        </g>
        <g data-name="Gluehbirne" id="d">
          <path d="M24.02592,.60001c9.57227,0,17.33231,7.76007,17.33231,17.33255,0,8.09006-6.14804,13.5267-11.55499,22.90051-.14975,.25952-.33701,.47464-.54877,.65964l.00046,.0051,.03427,.73691,.08701,1.86432-1.73026,.51669,1.7789,.53131,.06531,1.39627-2.2778,.68021,2.34198,.69946,.03339,.71277c-.87979,.17813-1.77451,.31375-2.68213,.40723-.16699,1.45023-1.38455,2.58066-2.87958,2.58066s-2.71289-1.13037-2.87985-2.58066c-.90735-.09344-1.80206-.22913-2.68155-.40723l.03281-.71268,2.34259-.69955-2.27814-.67679,.06503-1.39975,1.7789-.53125-1.72995-.51395,.12158-2.60397,.00021-.00482c-.21176-.185-.39932-.40027-.5491-.65991-5.40692-9.37381-11.55499-14.81046-11.55499-22.90051C6.69356,8.36008,14.4536,.60001,24.02592,.60001m0-.60001C14.13798,0,6.09355,8.04453,6.09355,17.93256c0,5.83337,3.12762,10.37967,6.74908,15.64383,1.60529,2.33344,3.26523,4.74631,4.88617,7.55646,.12653,.21936,.27719,.41922,.45676,.60544l-.10907,2.33551-.02191,.46933,.24942,.0741-.25668,.07666-.01978,.42526-.06503,1.39975-.02179,.46924,.45029,.13376,.34711,.10312-.41238,.12314-.40808,.12189-.01959,.42545-.03281,.71268-.02365,.51361,.50394,.10205c.74921,.1517,1.51862,.27466,2.29315,.36658,.39352,1.53067,1.77377,2.63324,3.38733,2.63324,1.61337,0,2.9935-1.10257,3.38705-2.63324,.77493-.09195,1.54456-.2149,2.29373-.36658l.50433-.10211-.02408-.51404-.03339-.71277-.0199-.42505-.40771-.1218-.41693-.12451,.35269-.10532,.44958-.13425-.02191-.46869-.06531-1.39627-.01987-.42511-.26163-.07816,.25473-.07605-.02188-.46866-.08698-1.86432-.02182-.46893c.17923-.18594,.32968-.38565,.45618-.60486,1.62097-2.81021,3.28091-5.22308,4.88617-7.55649,3.62152-5.26419,6.74915-9.81049,6.74915-15.64389C41.95823,8.04453,33.91383,0,24.02592,0h0Z" fill="#fff" />
        </g>
        <g data-name="Leuchtturm" id="e">
          <path d="M24.02588,15.05113c2.82054,0,4.85638,1.15754,6.21697,2.06219l-.12529-2.03009-6.09168-5.01986-6.12379,5.05183-.09302,1.99812c1.36059-.90465,3.39643-2.06219,6.21681-2.06219Z" fill="#fff" />
          <path d="M24.0259,27.01285c-2.15406,0-3.66207,.71374-4.6124,1.27054l-.61586,13.20961,.00795,.00305c1.66825,.43034,3.41757,.66359,5.2203,.66359s3.55174-.23326,5.21999-.66347l.00858-.00345-.61586-13.2091c-.95033-.5568-2.45833-1.27078-4.61272-1.27078Z" fill="#fff" />
        </g>
        <g data-name="Licht" id="f">
          <circle cx="24.02594" cy="21.31656" fill="#ffd200" r="2.64249" />
          <path d="M18.91459,21.31777H0c.38115,2.04396,1.04795,3.96426,1.92978,5.84141l16.95259-4.67317,.03223-1.16825Z" fill="#ffd200" />
          <path d="M49.19224,14.63938l-20.08617,5.53713,.03135,1.14126h19.80873c.24375-1.30719,.37734-2.6622,.37734-4.08745,0-.87473-.04453-1.73906-.13125-2.59094Z" fill="#ffd200" />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
