import { IcLeads } from '@components/icons';
import resources from './i18n';

export const rootPath = '';

const config = {
  name: 'leads',
  rootPath,
  i18n: {
    resources,
  },
  menu: {
    getSections: (t) => [
      {
        title: t('modules.leads.title'),
        acl: [],
        items: [
          {
            title: t('modules.leads.menu.leads'),
            path: `${rootPath}/leads`,
            icon: <IcLeads />,
            acl: [
              'api::lead.lead.find',
            ],
          },
        ],
      },
    ],
  },
};

export default config;
