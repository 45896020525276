import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      fill="none"
      height="1em"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 32 32"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M.56 4.59v22.82h30.88V12.64l-8.06-8.05H.56z" />
      <path d="M31.44 12.64h-8.06V4.59M7.28 9.96h9.39M7.28 13.99h9.39M7.28 22.04h17.44M7.28 18.01h17.44" />
    </svg>
  );
}

export default SvgComponent;
